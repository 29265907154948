<template>
  <div class="bg" style="min-height: 100vh; display: flex; flex-direction: column;">
    <div style="width: 100%;">
      <Navbar></Navbar>
    </div>
    <div class="body">
      <div class="container has-text-centered is-max-widescreen">
        <h1 class="textTitle">Zamów</h1>
        <div class="columns mt-4 is-multiline is-centered">
          <div class="column is-12 is-6-desktop has-text-centered has-text-left-desktop">
            <input class="inp mb-3" placeholder="Imię i Nazwisko" v-model="formData.name"><br>
            <label class="help is-danger mb-2" v-if="errors.name">{{ errors.name }}</label>
            <input class="inp mt-3 mb-1" placeholder="Mail np. example@gmail.com" v-model="formData.email"><br>
            <label class="help is-danger mb-2" v-if="errors.email">{{ errors.email }}</label>
            <textarea class="textar mt-3 mb-1" placeholder="Treść" v-model="formData.content"/><br>
            <label class="help is-danger mb-2" v-if="errors.content">{{ errors.content }}</label>
            <a class="submitBtn mt-4 mb-2" @click="sendForm">Wyślij</a>
            <label :class="['help',  {'is-success': success}, {'is-danger': !success}]" v-if="message">{{
                message
              }}</label>
            <div class="iconsContainer">
              <a class="ic" :href="getEnv('FACEBOOK')" target="_blank">
                <font-awesome-icon icon="fa-brands fa-facebook-square"></font-awesome-icon>
              </a>
              <a class="ic" :href="getEnv('INSTAGRAM')" target="_blank">
                <font-awesome-icon icon="fa-brands fa-instagram"></font-awesome-icon>
              </a>
              <a class="ic" :href="getEnv('TIKTOK')" target="_blank">
                <font-awesome-icon icon="fa-brands fa-tiktok"></font-awesome-icon>
              </a>
              <a class="ic extra-margin-left" :href="'/kontakt'">
                <font-awesome-icon icon="fa-solid fa-phone"></font-awesome-icon>
              </a>
              <a class="ic" :href="'/kontakt'">
                <font-awesome-icon icon="fa-solid fa-house"></font-awesome-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: auto">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
import {getEnv} from '@/utils.js';

export default {
  components: {
    Footer, Navbar
  }, data() {
    return {
      success: undefined,
      message: undefined,
      errors: {
        content: undefined,
        email: undefined,
        name: undefined
      }, formData: {
        email: undefined
      }
    }
  },
  methods: {
    getEnv,
    submitForm(json) {
      const vue = this;

      json.access_key = this.getEnv("FORM_KEY");
      console.log(json)

      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(json)
      }).then(async (response) => {
        let json = await response.json();

        if (json.success) {
          vue.success = true;
          vue.message = "Wysłano formularz!";
        } else {
          vue.success = false;
          vue.message = "Wystąpił błąd podczas wysyłania formularza!";
        }
      });
    }, validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }, checkEmail() {
      if (this.formData.email) {
        if (!this.validateEmail(this.formData.email)) {
          return "Nieprawidłowy adres email!";
        }
      } else {
        return "Musisz podać adres email!";
      }
      return undefined;
    }, verifyLength(value, min, max) {
      if (value) {
        if (value.length < min) {
          return `Wartość musi zawierać minimalnie ${min} znaków!`;
        }
        if (value.length > max) {
          return `Wartość musi zawierać maksymalnie ${max} znaków!`;
        }
      } else {
        return 'Wartość jest wymagana!';
      }
      return undefined;
    }, sendForm() {
      const verifyName = this.verifyLength(this.formData.name, 5, 40);
      const verifyContent = this.verifyLength(this.formData.content, 10, 1000);
      const verifyEmail = this.checkEmail();

      if (verifyEmail === undefined && verifyName === undefined && verifyContent === undefined) {
        this.submitForm(this.formData);
      } else {
        this.errors.email = verifyEmail;
        this.errors.name = verifyName;
        this.errors.content = verifyContent;
      }
    }
  }, watch: {
    'formData.email'() {
      this.errors.email = this.checkEmail();
    },
    'formData.name'() {
      this.errors.name = this.verifyLength(this.formData.name, 5, 40);
    },
    'formData.content'() {
      this.errors.content = this.verifyLength(this.formData.content, 10, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.inp, .textar {
  padding: 15px 20px;
  border-radius: 24px;
  width: 100%;
  background-color: #111111;
  border: none;
  color: #fff;
  font-size: 1.1rem;

  @include tablet-only {
    width: 50%;
  }
}

.inp::placeholder, .textar::placeholder {
  font-size: 1.4rem;
  font-weight: 300;
  opacity: 0.8;
}

.inp:focus, .textar:focus {
  outline: var(--primary) solid 1px;
}

.textar {
  height: 150px;
  resize: none;
}

.iconsContainer {
  display: flex;
  gap: 20px;

  .ic {
    font-size: 2.5rem;
    color: var(--primary)
  }
}

.submitBtn {
  color: #ffffff;
  font-weight: 800;
  font-size: 1.25rem;

  border-radius: 24px;
  padding: 8px 48px;
  background-color: var(--primary);

  display: inline-block;
}

.centerr {
  display: flex;
  gap: 40px;
  flex-direction: column;

  @include touch {
    gap: 30px;
  }
}

.ic {
  transition: color .5s;

  &:hover {
    color: #fff;
  }
}

.iconsContainer {
  justify-content: center;
  margin-top: 50px;
}

</style>
