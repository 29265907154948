<template>
  <div class="bg" style="min-height: 100vh; display: flex; flex-direction: column;">
    <div style="width: 100%;">
      <Navbar></Navbar>
    </div>
    <div class="body">
      <div class="container has-text-centered">
        <span class="textDesc rulesText">
          1. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          2. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          3. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          4. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          5. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          6. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          7. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          8. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          9. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          10. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          11. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
          <br>
          12. Strasznie wczoraj zachlałem. Paliłem blanty do piątej rano. Film mi się urwał jak leżałem w rurze. Teraz mnie krzyż napierdala. Trochę się przespałem, ale musiałem wstać rano bo mam obowiązki. Mam dziecko. Niektórzy mówią, że nie można chlać jak się ma dzieci, ale to nie prawda. Można, tylko trzeba wstawać rano. Na tym polega odpowiedzialność.
          <br>
        </span>
      </div>
    </div>
    <div style="margin-top: auto">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {Navbar, Footer},
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.rules-body {
  height: 100%;
}

.bg {
  background-color: #171717;
  min-height: 100vh;
}

.rulesText {
  text-align: justify;
}

.body {
  margin-top: 4em;
  max-height: calc(100vh - 8em - 190px);
  overflow: auto;
  display: block;
  width: 100%;

  @include touch {
  max-height: calc(100vh - 8em - 320px);
  }
}
</style>